import React, { createContext, useState, useEffect } from 'react';
import { SessionKit } from '@wharfkit/session';
import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat';

const webRenderer = new WebRenderer();
const sessionKit = new SessionKit({
  appName: 'scurvybot',
  chains: [
    {
      id: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
      url: 'https://wax.greymass.com',
    },
  ],
  ui: webRenderer,
  walletPlugins: [
    new WalletPluginAnchor(),
    new WalletPluginCloudWallet(),
    new WalletPluginWombat(),
  ],
  requestAccount: 'scurvybot',
});

// Create context
export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(null);

  // Restore session if it exists
  useEffect(() => {
    const restoreSession = async () => {
      try {
        const { session: restoredSession } = await sessionKit.restore();
        if (restoredSession) {
          const serializedRestoredSession = restoredSession.serialize();
          setSession(serializedRestoredSession);
        } else {
          console.log('No session to restore');
        }
      } catch (error) {
        console.error('Failed to restore session:', error);
      }
    };
    restoreSession();
  }, []);

  // Login function
  const loginWallet = async () => {
    try {
      const { session: newSession } = await sessionKit.login({
        requestPermission: 'active',
      });
      const serializedNewSession = newSession.serialize();
      setSession(serializedNewSession);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  // Logout function
  const logoutWallet = () => {
    setSession(null);
    sessionKit.logout();
  };

  return (
    <SessionContext.Provider value={{ session, loginWallet, logoutWallet }}>
      {children}
    </SessionContext.Provider>
  );
};
