import React, { createContext, useState, useEffect, useContext } from 'react';
import { Account, AccountKit } from '@wharfkit/account';
import { APIClient } from '@wharfkit/antelope';
import { Chains } from '@wharfkit/common';
import { SessionContext } from './SessionContext';

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const { session } = useContext(SessionContext);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initAccount = async () => {
      if (!session) {
        // console.log("No session found, skipping account initialization.");
        setLoading(false);
        return;
      }

      try {
        // console.log("Initializing account with session:", session);
        setLoading(true);

        // version 2

        const accountArgs = {
          data: {
            account_name: session.actor.toString()
          },
          client: new APIClient({ url: "https://wax.greymass.com" }),
        }

        const account = new Account(accountArgs)

        setAccount(account);
        
      } finally {
        setLoading(false);
      }
    };

    initAccount();
  }, [session]);

  return (
    <AccountContext.Provider value={{ account, loading, error }}>
      {children}
    </AccountContext.Provider>
  );
};