import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Box, Typography, Switch, Divider, Tab, Tabs, Grid, TextField, CircularProgress, Button, 
  FormControlLabel, InputAdornment } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'; // Import Axios

// Normalizes the setting value based on its data type
const normaliseSettingValue = (value, dataType) => {
  switch (dataType) {
    case 'boolean':
      return value;
    case 'int':
      return value !== null ? parseInt(value, 10).toString() : '';
    case 'float':
      return value !== null ? parseFloat(value).toString() : '';
    case 'percent':
      return value !== null ? (parseFloat(value) * 100).toString() : '';
    case 'string':
    default:
      return value === null ? '' : value;
  }
};

// Function to compare values considering type conversions
const compareValues = (currentValue, initialValue, dataType) => {
  const normalizedCurrent = normaliseSettingValue(currentValue, dataType);
  const normalizedInitial = normaliseSettingValue(initialValue, dataType);
  return normalizedCurrent === normalizedInitial;
};

// Generate validation rules based on data type and dependencies
const getValidationRules = (setting, getValues) => {
  switch (setting.setting_data_type) {
    case 'boolean':
      return {};
    case 'int':
      return {
        valueAsNumber: true,
        validate: (value) => !isNaN(value) && value >= 0 || `${setting.setting_label} must be a valid number`,
      };
    case 'float':
      return {
        valueAsNumber: true,
        validate: (value) => !isNaN(value) && value >= 0 || `${setting.setting_label} must be a valid number`,
      };
    case 'percent':
      return {
        valueAsNumber: true,
        validate: (value) => (!isNaN(value) && value >= 0) || `${setting.setting_label} must be a valid percentage`,
      };
    case 'string':
    default:
      return {
      };
  }
};

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [tabLabels, setTabLabels] = useState([]);

  const {
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {},
    shouldUnregister: false, // Keep all fields registered even when unmounted
  });

  const tabContainerRef = useRef(null);

  // Define dependent fields and mutual exclusivity
  const dependentFields = useMemo(
    () => [
      {
        triggerFields: ['dig_template_req', 'dig_template'],
        dependentField: 'dig_template',
        condition: (values) => values.dig_template_req,
        errorMessage: 'Template ID is required when Dig Template is active',
      },
      {
        triggerFields: ['dig_booster', 'dig_booster_template'],
        dependentField: 'dig_booster_template',
        condition: (values) => values.dig_booster,
        errorMessage: 'Booster Template ID is required when Dig Booster is active',
      },
      {
        triggerFields: ['dig_booster', 'dig_booster_percentage'],
        dependentField: 'dig_booster_percentage',
        condition: (values) => values.dig_booster,
        errorMessage: 'Booster Percentage is required when Dig Booster is active',
      },
    ],
    []
  );

  const mutuallyExclusiveGroups = useMemo(
    () => [
      ['chat_chest_command', 'chat_chest_math'],
      // Add more mutually exclusive field pairs here
    ],
    []
  );

  // Validation function for dependent fields
  const validateDependentFields = useCallback(
    (values) => {
      dependentFields.forEach(({ dependentField, condition, errorMessage }) => {
        if (condition(values) && !values[dependentField]) {
          setError(dependentField, { type: 'required', message: errorMessage });
        } else {
          clearErrors(dependentField);
        }
      });
    },
    [dependentFields, setError, clearErrors]
  );

  // Enforce mutual exclusivity between field pairs
  const enforceMutualExclusivity = useCallback(
    (values) => {
      mutuallyExclusiveGroups.forEach(([fieldA, fieldB]) => {
        if (values[fieldA] && values[fieldB]) {
          // Deactivate fieldB if fieldA is activated
          setValue(fieldB, false);
          clearErrors(fieldB);
          console.log(`Mutually exclusive: Setting ${fieldB} to false`);
        }
      });
    },
    [mutuallyExclusiveGroups, setValue, clearErrors]
  );

  // Combined validation function
  const validateFields = useCallback(
    (values) => {
      validateDependentFields(values);
      enforceMutualExclusivity(values);
    },
    [validateDependentFields, enforceMutualExclusivity]
  );

  // Function to update settings
  const updateSettings = useCallback(
    async (settingKey, value, dataType) => {
      let normalizedValue = normaliseSettingValue(value, dataType);

      // Convert boolean to string if necessary
      if (dataType === 'boolean') {
        normalizedValue = value ? 'true' : 'false';
      }

      console.log(`Updating ${settingKey} with value: ${normalizedValue}`);

      try {
        await axios.post(
          '/api/channel-settings',
          {
            settings: [
              {
                setting_key: settingKey,
                setting_value: normalizedValue,
              },
            ],
          },
          { withCredentials: true }
        );
        console.log(`Successfully updated ${settingKey} to ${normalizedValue}`);
      } catch (error) {
        console.error(`Error updating ${settingKey}:`, error);
      }
    },
    []
  );

  // Fetch settings and initialize form
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch('/api/channel-settings?setting_type=all_settings', {
          credentials: 'include', // Ensure cookies are included
        });
        const result = await response.json();

        if (result.success) {
          const normalisedSettings = result.data.map(setting => ({
            ...setting,
            setting_value:
              setting.setting_data_type === 'boolean'
                ? setting.setting_value === 'true'
                : normaliseSettingValue(setting.setting_value, setting.setting_data_type),
          }));
          setSettingsData(normalisedSettings);

          const uniqueTypes = [...new Set(normalisedSettings.map(setting => setting.setting_type))];
          setTabLabels(uniqueTypes);

          const defaultValues = normalisedSettings.reduce((acc, setting) => {
            acc[setting.setting_key] = setting.setting_value;
            return acc;
          }, {});
          setInitialValues(defaultValues);
          reset(defaultValues);
          validateFields(defaultValues);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [reset, validateFields]);

  // Watch for changes and validate accordingly
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      validateFields(values);
    });

    return () => subscription.unsubscribe();
  }, [watch, validateFields]);

  // Trigger validation on tab change to validate fields on the new tab
  useEffect(() => {
    trigger(); // Validate all fields when tab changes
  }, [activeTab, trigger]);

  // Render your form fields dynamically
  const renderFormFields = (settingType) => {
    if (!settingsData) return null;

    return settingsData
      .filter((setting) => setting.setting_type === settingType)
      .map((setting) => {
        const { setting_key, setting_label, setting_data_type } = setting;
        const validationRules = getValidationRules(setting, getValues);

        // Determine the field type based on data type
        let fieldComponent = null;
        switch (setting_data_type) {
          case 'boolean':
            fieldComponent = (
              <Controller
                key={setting_key}
                name={setting_key}
                control={control}
                render={({ field }) => {
                  const isMutuallyExclusive = mutuallyExclusiveGroups.some(group => group.includes(setting_key));
                  let disabled = false;

                  if (isMutuallyExclusive) {
                    const [fieldA, fieldB] = mutuallyExclusiveGroups.find(group => group.includes(setting_key));
                    disabled = fieldA === setting_key ? watch(fieldB) === true : watch(fieldA) === true;
                  }

                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!field.value} // Ensure it's a boolean
                          onChange={async (e) => {
                            const newValue = e.target.checked;
                            console.log(`Toggling ${setting_key} to ${newValue}`);
                            field.onChange(newValue); // Update with boolean

                            if (isMutuallyExclusive && newValue) {
                              const [fieldA, fieldB] = mutuallyExclusiveGroups.find(group => group.includes(setting_key));
                              const oppositeField = setting_key === fieldA ? fieldB : fieldA;
                              setValue(oppositeField, false); // Set opposite field to false
                              clearErrors(oppositeField);
                              console.log(`Mutually exclusive: Setting ${oppositeField} to false`);
                              await updateSettings(oppositeField, false, setting_data_type);
                            }
                            await updateSettings(setting_key, newValue, setting_data_type);
                          }}
                        />
                      }
                      label={setting_label}
                      disabled={disabled}
                    />
                  );
                }}
              />
            );
            break;
          case 'int':
          case 'float':
          case 'percent':
            fieldComponent = (
              <Controller
                key={setting_key}
                name={setting_key}
                control={control}
                rules={validationRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={setting_label}
                    fullWidth
                    type={setting_data_type === 'percent' ? 'number' : 'text'}
                    InputProps={{
                      endAdornment: setting_data_type === 'percent' ? <InputAdornment position="end">%</InputAdornment> : null,
                    }}
                    error={!!errors[setting_key]}
                    helperText={errors[setting_key]?.message}
                    disabled={
                      // Disable if this field has dependencies and the condition is not met
                      dependentFields.some(df => df.dependentField === setting_key) &&
                      !dependentFields.find(df => df.dependentField === setting_key).condition(getValues())
                    }
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    onBlur={() => {
                      console.log(`Blur on ${setting_key} with value ${field.value}`);
                      updateSettings(setting_key, field.value, setting_data_type);
                    }}
                  />
                )}
              />
            );
            break;
          case 'string':
          default:
            fieldComponent = (
              <Controller
                key={setting_key}
                name={setting_key}
                control={control}
                rules={validationRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={setting_label}
                    fullWidth
                    error={!!errors[setting_key]}
                    helperText={errors[setting_key]?.message}
                    disabled={
                      // Disable if this field has dependencies and the condition is not met
                      dependentFields.some(df => df.dependentField === setting_key) &&
                      !dependentFields.find(df => df.dependentField === setting_key).condition(getValues())
                    }
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    onBlur={() => {
                      console.log(`Blur on ${setting_key} with value ${field.value}`);
                      updateSettings(setting_key, field.value, setting_data_type);
                    }}
                  />
                )}
              />
            );
        }

        return (
          <Box key={setting_key} mb={2}>
            {fieldComponent}
          </Box>
        );
      });
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            aria-label="Settings Tabs"
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
            textColor="inherit" // Inherit text color
            indicatorColor="primary" // Optional: Change indicator color
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  color: index === activeTab ? '#fff' : 'rgba(255, 255, 255, 0.7)', // Active: white, Inactive: semi-transparent white
                  '&.Mui-selected': {
                    fontWeight: 'bold', // Bold text for active tab
                  },
                }}
              />
            ))}
          </Tabs>

          {tabLabels.map((label, index) => (
            <TabPanel key={index} value={activeTab} index={index}>
              <form>
                {renderFormFields(label)}
              </form>
            </TabPanel>
          ))}
        </Box>
      )}
    </div>
  );
};

// Helper component for Tab Panels
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default Settings;
