import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Button, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { AuthModal } from '../components';
import homeVideo from '../streamer.mp4';
import placeholderImage from '../streamerPlaceholder.jpg';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  flex: 1,
  overflow: 'hidden',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 128px)',
  [theme.breakpoints.down('sm')]: {
    overflow: 'hidden',
  },
}));

const BackgroundMedia = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '100vh',
  zIndex: 0,
}));

const BackgroundVideo = styled('video')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  filter: 'brightness(35%)',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const BackgroundImage = styled('img')(({ theme }) => ({
  display: 'none',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  filter: 'brightness(35%)',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '4rem',
  marginBottom: theme.spacing(2.5),
  textShadow: '4px 4px #000',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const CTAButton = styled('button')(({ theme }) => ({
  fontSize: '1.5rem',
  padding: '10px 20px',
  color: '#FFFFFF',
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  cursor: 'pointer',
  marginTop: '20px',
  borderRadius: '10px',
  transition: 'transform 0.3s ease',
  zIndex: 1,
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const StyledButton = styled(Button)({
  alignSelf: 'center',
  marginTop: 'auto',
});

const FeaturesGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3.75),
  width: '80%',
  gap: theme.spacing(2.5),
  alignItems: 'stretch',
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  backgroundColor: '#444B6E',
  padding: theme.spacing(2.5),
  borderRadius: '10px',
  width: '280px',
  textAlign: 'center',
  transition: 'box-shadow 0.3s ease',
  color: '#fff',
  '&:hover': {
    boxShadow: '0px 4px 20px rgba(255, 69, 0, 0.5)',
  },
}));

const FeatureContainer = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
});

const FeaturePaper = styled(Paper)({
  flex: '1 1 30%',
  display: 'flex',
  flexDirection: 'column',
  margin: '10px',
});

function BasicPage() {
  const videoRef = useRef(null);
  const { authState } = useAuth();
  const navigate = useNavigate();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadeddata', () => {
        setIsVideoLoaded(true);
      });
    }
  }, []);

  const handleCTAButtonClick = () => {
    if (authState.isAuthenticated) {
      if (authState.role === 'streamer') {
        navigate('/streamer-dashboard');
      } else if (authState.role === 'viewer') {
        navigate('/viewer-dashboard');
      }
    } else {
      setAuthModalOpen(true);
    }
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  return (
    <Container>
      <BackgroundMedia>
        <BackgroundVideo autoPlay muted loop ref={videoRef}>
          <source src={homeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </BackgroundVideo>
        <BackgroundImage src={placeholderImage} alt="Background placeholder" />
      </BackgroundMedia>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        {/* Top Section */}
        <Title>Empower your stream with Scurvy Bot</Title>

        {/* Button Section */}
        <CTAButton 
          onClick={handleCTAButtonClick}>
          {authState.isAuthenticated ? 'Go To Your Dashboard' : 'Get Started Now!'}
        </CTAButton>

        {/* Grid of Feature Cards */}
        <FeaturesGrid container spacing={2} justifyContent="center">
          <Grid item>
            <FeatureCard elevation={3}>
              <Typography variant="h5" gutterBottom>Ultimate stream Loyalty</Typography>
              <Typography variant="body1">
                Engage with streams to earn loyalty tokens through actions like chatting, subscribing or playing chat games
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item>
            <FeatureCard elevation={3}>
              <Typography variant="h5" gutterBottom>Redeem for crypto</Typography>
              <Typography variant="body1">
                Claim real blockchain tokens from the streamer’s custom pools and redemption rates
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item>
            <FeatureCard elevation={3}>
              <Typography variant="h5" gutterBottom>Dig for prizes</Typography>
              <Typography variant="body1">
                Use loyalty tokens to dig for a chance to find NFTs or other digital prizes in the streamer's custom prize pools
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item>
            <FeatureCard elevation={3}>
              <Typography variant="h5" gutterBottom>All in one place</Typography>
              <Typography variant="body1">
                Every tool you could need as a streamer - providing enhanced loyalty and rewards through community engagement
              </Typography>
            </FeatureCard>
          </Grid>
        </FeaturesGrid>
      </Box>

      <AuthModal open={authModalOpen} onClose={handleAuthModalClose} />
    </Container>
  );
}

export default BasicPage;