import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Paper, Typography, Grid, Avatar, List, ListItem, Alert, ListItemIcon } from '@mui/material';
import { Favorite, ArrowUpward, ArrowDownward, BuildCircle } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { formatDistanceToNow } from 'date-fns';

const StreamerDashboard = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [followerCount, setFollowerCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [recentActivity, setRecentActivity] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [recentFollowers, setRecentFollowers] = useState([]);

  useEffect(() => {
    const fetchStreamerData = async () => {
      try {
        const userResponse = await axios.get('/api/twitch/user', { withCredentials: true });
        setUserInfo(userResponse.data);

        const followersCountResponse = await axios.get('/api/twitch/followers/count', { withCredentials: true });
        setFollowerCount(followersCountResponse.data.total);

        const subscribersResponse = await axios.get('/api/twitch/subscribers/count', { withCredentials: true });
        setSubscriberCount(subscribersResponse.data.total);

        const recentActivityResponse = await axios.get('/api/recent-activity', { withCredentials: true });
        setRecentActivity(recentActivityResponse.data.data);

        const followersResponse = await axios.get('/api/twitch/followers/recent', { withCredentials: true });
        setRecentFollowers(followersResponse.data);

        const notificationsResponse = await axios.get('/api/notifications', { withCredentials: true });
        if (Array.isArray(notificationsResponse.data.data)) {
          setNotifications(notificationsResponse.data.data);
        } else {
          setNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStreamerData();
  }, []);

  const DigIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px', color: '#ff7e04' }}>
      <path fill="currentColor" d="m15.1 1.81l-2.83 2.83c-.77.78-.77 2.05 0 2.83l1.41 1.41l-4.55 4.55l-2.82-2.83L4.89 12c-4.95 5-1.39 8.5-1.39 8.5S7 24 12 19.09l1.41-1.41l-2.8-2.8l4.54-4.54l1.39 1.39c.78.77 2.05.77 2.83 0L22.2 8.9zm2.83 8.47L16.55 8.9l-1.44-1.44l-1.4-1.4l1.41-1.41l4.23 4.23z"/>
    </svg>
  );

  const GiftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px', color: '#ff7e04' }}>
      <path d="M11 14v8H7a3 3 0 0 1-3-3v-4a1 1 0 0 1 1-1zm8 0a1 1 0 0 1 1 1v4a3 3 0 0 1-3 3h-4v-8zM16.5 2a3.5 3.5 0 0 1 3.163 5H20a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-7V7h-2v5H4a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h.337A3.5 3.5 0 0 1 4 5.5C4 3.567 5.567 2 7.483 2c1.755-.03 3.312 1.092 4.381 2.934l.136.243c1.033-1.914 2.56-3.114 4.291-3.175zm-9 2a1.5 1.5 0 0 0 0 3h3.143C9.902 5.095 8.694 3.98 7.5 4m8.983 0c-1.18-.02-2.385 1.096-3.126 3H16.5a1.5 1.5 0 1 0-.017-3"/>
    </svg>
  );
 
  const getIcon = (actionType) => {
    switch (actionType) {
      case 'deposit':
        return <ArrowDownward sx={{ color: 'green', marginRight: '4px' }} />;
      case 'withdraw':
        return <ArrowUpward sx={{ color: 'red', marginRight: '4px' }} />;
      case 'dig':
        return <DigIcon />;
      case 'prize':
        return <GiftIcon />;
      case 'follow':
        return <Favorite sx={{ color: '#ff7e04', marginRight: '4px' }} />;
      default:
        return null;
    }
  };

  const formatDate = (date) => formatDistanceToNow(new Date(date), { addSuffix: true });

  const getAssetText = (count) => (count === 1 ? 'asset' : 'assets');

  // Notifications
  const renderNotificationRow = (notification, index) => {
    const getBackgroundColor = (notificationType) => {
      switch (notificationType) {
        case 'info':
          return '#e0f7fa'; // Light cyan for info
        case 'warning':
          return '#fff3e0'; // Light orange for warning
        case 'error':
          return '#ffebee'; // Light red for error
        case 'success':
          return '#e8f5e9'; // Light green for success
        default:
          return '#fff'; // Default white for unspecified
      }
    };
  
    return (
      <Alert
        key={index}
        severity={notification.notification_type}
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: getBackgroundColor(notification.notification_type),
          marginBottom: '6px',
          borderRadius: 1,
          color: '#000',
          padding: '4px 8px',
        }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px', padding: 0, display: 'flex', alignItems: 'center' }}>
          {getIcon(notification.notification_type)}
        </ListItemIcon>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, padding: 0 }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ margin: 0, lineHeight: 1.2, padding: 0 }}>
            {notification.title}
          </Typography>
          <Box sx={{ margin: 0, lineHeight: 1.2, padding: 0 }}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                p: ({ node, ...props }) => <Typography variant="body2" sx={{ margin: 0, padding: 0 }} {...props} />,
              }}
            >
              {notification.message}
            </ReactMarkdown>
          </Box>
        </Box>
      </Alert>
    );
  }; 

  // Recent Activity
  const renderActivityRow = (activity, index) => (
      <ListItem
        key={index}
      sx={{ p: 1, backgroundColor: '#fff', marginBottom: '6px', borderRadius: 1, color: '#000' }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px' }}>{getIcon(activity.action_type)}</ListItemIcon>
        <Typography>
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {activity.action_type === 'deposit' ? 'Deposited' : activity.action_type === 'withdraw' ? 'Withdrew' : activity.action_type === 'dig' ? 'Dug up' : activity.action_type === 'prize' ? 'Sent out' : 'Dug up'} {activity.action_count} {getAssetText(activity.action_count)}
          </Box>{' '}
          <Box component="span" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
            {formatDate(activity.action_date)}
          </Box>
        </Typography>
      </ListItem>
  );

  // Recent Followers
  const renderFollowerRow = (follower, index) => (
      <ListItem
        key={index}
      sx={{ p: 1, backgroundColor: '#fff', marginBottom: '6px', borderRadius: 1, color: '#000' }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px' }}>{getIcon('follow')}</ListItemIcon>
        <Typography>
          <Box component="span" sx={{ fontWeight: 'bold' }}>{follower.user_name}</Box>{' '}
          <Box component="span" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
            {formatDate(follower.timestamp)}
          </Box>
        </Typography>
      </ListItem>
  );

  if (!userInfo) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 2, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      {/* Top Section */}
      <Box sx={{ padding: '12px 0', marginBottom: 2, color: '#fff', flex: '0 0 13%' }}>
        <Grid container alignItems="center" spacing={4}>
          {/* Streamer Info */}
          <Grid item xs={12} md={4}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80 }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  Hello, {userInfo.display_name}!
                </Typography>
                <Typography variant="body1">Welcome to your Stream Dashboard</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Counts Section (Followers, Subscribers) */}
          <Grid item xs={12} md={8}>
            <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, backgroundColor: '#424867', color: '#fff', textAlign: 'center' }}>
                  <Typography variant="h6">Followers</Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: '#ff7e04',
                      fontSize: { xs: '1.5rem', md: '2.5rem' },
                    }}
                  >
                    {Number(followerCount).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, backgroundColor: '#424867', color: '#fff', textAlign: 'center' }}>
                  <Typography variant="h6">Subscribers</Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: '#ff7e04',
                      fontSize: { xs: '1.5rem', md: '2.5rem' },
                    }}
                  >
                    {Number(subscriberCount).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} sx={{ flex: 1, height: '100%' }}>

        {/* Recent Notifications Section */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', maxHeight: '65vh' }}>
          <Paper
            sx={{
              flexGrow: 1,
              padding: 0,
              backgroundColor: '#424867',
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}>
            <Box sx={{ 
              padding: '16px 0px 0px 16px', 
              borderTopLeftRadius: 1,
              borderTopRightRadius: 1,
              position: 'sticky', 
              backgroundColor: '#424867', 
              zIndex: 10 
            }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Recent Notifications
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '0px 8px 8px 8px' }}>
              <List>
                {notifications.map((notification, index) => renderNotificationRow(notification, index))}
              </List>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Scurvy Activity Section */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', maxHeight: '65vh' }}>
          <Paper
            sx={{
              flexGrow: 1,
              padding: 0,
              backgroundColor: '#424867',
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}>
            <Box sx={{ 
              padding: '16px 0px 0px 16px',
              borderTopLeftRadius: 1,
              borderTopRightRadius: 1, 
              position: 'sticky', 
              backgroundColor: '#424867', 
              zIndex: 10 
            }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Recent Scurvy Activity
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '0px 8px 8px 8px' }}>
              <List>
                {recentActivity.map((activity, index) => renderActivityRow(activity, index))}
              </List>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Followers Section */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', maxHeight: '65vh' }}>
          <Paper
            sx={{
              flexGrow: 1,
              padding: 0,
              backgroundColor: '#424867',
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}>
            <Box sx={{ 
              padding: '16px 0px 0px 16px', 
              borderTopLeftRadius: 1,
              borderTopRightRadius: 1,
              position: 'sticky', 
              backgroundColor: '#424867', 
              zIndex: 10 
            }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Recent Followers
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '0px 8px 8px 8px' }}>
              <List>
                {recentFollowers.map((follower, index) => renderFollowerRow(follower, index))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamerDashboard;